import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-screen", { dark: $setup.darkMode }])
  }, [
    _createVNode($setup["YoutubePlayer"], {
      class: "player",
      id: "kkOe4B8OuEc"
    }),
    _createVNode($setup["BackgroundImage"], { class: "bg" }),
    _createVNode($setup["ChatPanel"], { class: "chat" })
  ], 2))
}